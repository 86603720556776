// Homepage
import CheckBaoHanh from '../components/home/index'
import KichHoat from '../components/view/kichHoat'
import TraCuu from '../components/view/traCuu'
import YeuCau from '../components/view/yeuCau'
import TramBaoHanh from '../components/view/tramBaoHanh'
import HuongDan from '../components/view/huongDan'
import PhieuBaoHanh from '../components/view/phieuBaoHanh'
import ThoiHanBaoHanh from '../components/view/thoiHanBaoHanh'
import ChinhSachBaoHanh from '../components/view/chinhSachBaoHanh'
import TraCuuTinhTrangBaoHanh from '../components/view/tracuutinhtrangbaohanh'
import YeuCauDetail from '../components/view/yeucaudetail'
import TraCuuDetail from '../components/view/tracuuDetail'


export const routes = [
  {path:"/homepage", Component: CheckBaoHanh},
  {path:"/kich-hoat-bao-hanh", Component: KichHoat},
  {path:"/tra-cuu-bao-hanh", Component: TraCuu},
  {path:"/tra-cuu-bao-hanh-detail/:sdt/:sochungtu", Component: TraCuuDetail},

  {path:"/gui-yeu-cau", Component: YeuCau},
  {path:"/chinh-sach-bao-hanh", Component: ChinhSachBaoHanh},
  {path:"/khuyen-cao-su-dung", Component: HuongDan},
  {path:"/thoi-han-bao-hanh", Component: ThoiHanBaoHanh},
  {path:"/tram-bao-hanh/:slug", Component: TramBaoHanh},
  {path:"/phieu-bao-hanh/:id", Component: PhieuBaoHanh},
  {path:"/tra-cuu-tinh-trang-bao-hanh", Component: TraCuuTinhTrangBaoHanh},
  {path:"/gui-yeu-cau/:id", Component: YeuCauDetail},

]