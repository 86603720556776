import React from "react"

import pdfFile from "../../assets/pdf/Khuyen-cao-Ecovacs.pdf";

const HuongDan = () => {

  return (
    <div className="pdf-container">
    <embed style={{width:'100%',height:'100vh'}} src={pdfFile}
     frameborder="0" />
    </div>
//        <div className="container chinh-sach">
//       <div className="chinh-sach-bao-hanh-chung">
//         <h5 className="fw-bold text-center ">KHUYẾN CÁO SỬ DỤNG SẢN PHẨM</h5>
//         <div className="content row " >
       
//             <div className="col-sm-10">
//           <ul className="list-unstyled">
//             <li className="fw-bold mb-2">
//               <p>Thân gửi Quý khách hàng!</p>
//             </li>
//             <li className="mb-2">
//               <p>ECOVACS xin chân thành cảm ơn Quý khách đã tin tưởng và lựa chọn sản phẩm của chúng tôi</p>
//             </li>
//             <li className="mb-2">
//               <p>Hy vọng sản phẩm này sẽ là trợ lý đắc lực giúp công việc dọn dẹp nhà cửa của Quý khách trở nên 
// nhanh chóng, dễ dàng, tạo ra một không gian sống hiện đại và sạch sẽ</p>
//             </li>
//             <li className="mb-2">
//               <p>Dưới đây là một số lưu ý khi sử dụng, Quý khách vui lòng đọc kỹ để tránh những tình huống làm hư 
// hỏng hoặc giảm tuổi thọ robot. Các lỗi do người dùng sử dụng sai cách sẽ không được bảo hành</p>
//             </li>
//           </ul>
//           </div>
//           <div className="col-sm-2 csbh_image_banner ">
//               <img alt="Hình ảnh" style={{height:'7rem'}} className="h-48 w-full object-cover md:h-full md:w-48"  src={imageBanner}/>
//           </div>
        
//           <div className='fw-bold mb-2'>Robot chỉ hút bụi, không hút được nước</div>
//           <div className="col-sm-12 mb-4" style={{border:'1px solid black',display:'flex',padding:0}}>

//             <div className="col-sm-6" style={{textAlign:'center',borderRight:'1px solid black'}}>
//                 <div style={{backgroundColor:'#e2efd9',height:'30px'}}>
//                 <svg  xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#71b148" class="bi bi-check2" viewBox="0 0 16 16">
//   <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content">
//                 Nên lập tường ảo khu vực nhà vệ sinh, đóng cửa nhà vệ 
// sinh để đảm bảo robot không đi vào khu vực này.
//                 </div>
//             </div>
//             <div className="col-sm-6" style={{textAlign:'center'}}>

//             <div style={{backgroundColor:'#fce4d5',height:'30px'}}>
//             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#c02025" class="bi bi-x" viewBox="0 0 16 16">
//   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content">
//                 Không để robot đi vào các khu vực có nước trên sàn nhà, 
// nước bị hút vào động cơ máy sẽ gây chập cháy. Nếu 
// robot hút phải nước, Quý khách nên tắt nguồn ngay và 
// liên hệ với trung tâm bảo hành để được hỗ trợ xử lý đúng 
// cách
//                 </div>
//             <div>

// </div>
// <div>
  
// </div>
              
//             </div>
//             </div>
//             <div className='fw-bold mb-2'>Sử dụng các loại nước lau sàn và phụ kiện chính hãng
// </div>
//           <div className="col-sm-12 mb-4" style={{border:'1px solid black',display:'flex',padding:0}}>

//             <div className="col-sm-6" style={{textAlign:'center',borderRight:'1px solid black'}}>
//                 <div style={{backgroundColor:'#e2efd9',height:'30px'}}>
//                 <svg  xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#71b148" class="bi bi-check2" viewBox="0 0 16 16">
//   <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content mb-2">
//                 Nên sử dụng nước lau sàn và bộ phụ kiện chính hãng, 
// thay thế định kỳ các phụ kiện này để tăng tuổi thọ sản 
// phẩm
//                 </div>
//             </div>
//             <div className="col-sm-6" style={{textAlign:'center'}}>

//             <div style={{backgroundColor:'#fce4d5',height:'30px'}}>
//             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#c02025" class="bi bi-x" viewBox="0 0 16 16">
//   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content">
//                 Không sử dụng chất lỏng không tương thích như tinh 
// dầu, xà phòng, nước lau có nồng độ chất tẩy rửa cao,… 
// sẽ gây tắc hệ thống bơm hoặc ăn mòn làm nứt vỡ bình 
// chứa nước
//                 </div>
//             <div>

// </div>
// <div>
  
// </div>
              
//             </div>
//             </div>
//             <div className='fw-bold mb-2'>Vệ sinh định kỳ</div>
//           <div className="col-sm-12 mb-4" style={{border:'1px solid black',display:'flex',padding:0}}>

//             <div className="col-sm-6" style={{textAlign:'center',borderRight:'1px solid black'}}>
//                 <div style={{backgroundColor:'#e2efd9',height:'30px'}}>
//                 <svg  xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#71b148" class="bi bi-check2" viewBox="0 0 16 16">
//   <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content mb-2">
//                 Nên vệ sinh robot và trạm tối thiểu 1 lần/tuần. 
// Bộ lọc HEPA chỉ nên hút bụi và vệ sinh khô, không vệ sinh 
// bằng nước sẽ làm hỏng kết cấu màng lọc
//                 </div>
//             </div>
//             <div className="col-sm-6" style={{textAlign:'center'}}>

//             <div style={{backgroundColor:'#fce4d5',height:'30px'}}>
//             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#c02025" class="bi bi-x" viewBox="0 0 16 16">
//   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content">
//                 Không vệ sinh thường xuyên sẽ khiến bụi bẩn tích tụ gây 
// ma sát lớn, robot dễ bị cuốn tóc hoặc dị vật dẫn đến bị 
// kẹt, làm giảm tuổi thọ robot
//                 </div>
//             <div>

// </div>
// <div>
  
// </div>
              
//             </div>
//             </div>
//             <div className='fw-bold mb-2'>Cố định dock sạc tại 1 vị trí
// </div>
//           <div className="col-sm-12 mb-4" style={{border:'1px solid black',display:'flex',padding:0}}>

//             <div className="col-sm-6" style={{textAlign:'center',borderRight:'1px solid black'}}>
//                 <div style={{backgroundColor:'#e2efd9',height:'30px'}}>
//                 <svg  xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#71b148" class="bi bi-check2" viewBox="0 0 16 16">
//   <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content">
//                 Nên đặt cố định dock sạc tại nơi bằng phẳng, mặt lưng 
// dựa sát vào tường, mặt bên và mặt trước tối thiểu 
// 0.5-1.5m không có vật che chắn để đảm bảo tín hiệu kết 
// nối và robot dễ ra vào dock
//                 </div>
//             </div>
//             <div className="col-sm-6" style={{textAlign:'center'}}>

//             <div style={{backgroundColor:'#fce4d5',height:'30px'}}>
//             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#c02025" class="bi bi-x" viewBox="0 0 16 16">
//   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content">
//                 Không nên thay đổi vị trí dock sạc hoặc các vật dụng 
// trong nhà liên tục. Điều này có thể khiến robot không 
// nhận diện được vị trí để quay về dock, giảm độ linh hoạt 
// và kéo dài thời gian lau dọn. 
//                 </div>
//             <div>

// </div>
// <div>
  
// </div>
              
//             </div>
//             </div>
//             <div className='fw-bold mb-2'>Tắt nguồn robot nếu không sử dụng trong thời gian dài
// </div>
//           <div className="col-sm-12 mb-4" style={{border:'1px solid black',display:'flex',padding:0}}>

//             <div className="col-sm-6" style={{textAlign:'center',borderRight:'1px solid black'}}>
//                 <div style={{backgroundColor:'#e2efd9',height:'30px'}}>
//                 <svg  xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#71b148" class="bi bi-check2" viewBox="0 0 16 16">
//   <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content">
//                 Hãy sạc đầy pin cho robot rồi tắt nguồn, đồng thời rút 
// nguồn trạm sạc, nếu Quý khách không sử dụng robot 
// trong thời gian dài
//                 </div>
//             </div>
//             <div className="col-sm-6" style={{textAlign:'center'}}>

//             <div style={{backgroundColor:'#fce4d5',height:'30px'}}>
//             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#c02025" class="bi bi-x" viewBox="0 0 16 16">
//   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
// </svg>
//                 </div>
//                 <div className="mt-2 content">
//                 Không để robot cạn pin trong thời gian dài, sẽ ảnh hưởng 
// nghiêm trọng đến độ bền của pin
//                 </div>
//             <div>

// </div>
// <div>
  
// </div>
              
//             </div>
//             </div>
//           <label className='d-block'>(Quý khách vui lòng đọc kĩ điều kiện bảo hành và đổi trả trong 30 ngày bên dưới)</label> 
        
//         </div>
//       </div>
   
//           <ul className="list-unstyled">
//             <li className="mb-2"><p>Nếu sản phẩm gặp lỗi, Quý khách hãy liên hệ ngay tới đơn vị bán hàng hoặc trung tâm bảo hành để được hỗ trợ.</p></li>
//             <li className="mb-2"><p>Vui lòng lưu ý, trường hợp Quý khách tự ý mở máy sửa chữa, làm mất hoặc rách tem bảo hành robot sẽ không 
// được hưởng chế độ bảo hành miễn phí.
// </p></li>
//             <li className="mb-2 fw-bold"><p>Ecovacs xin chân thành cảm ơn!
// </p></li>
//           </ul>

//     </div> 
   
  )
}

export default HuongDan