import { toast } from "react-toastify"
import { 
  _getCategory, 
  _getCity, 
  _getDistrict, 
  _getWard,
  _getProductErp,
  _checkBaoHanh,
  _addBaoHanh,
  _traCuuBaoHanh,
  _getThuonghieu,
  _getProductName,
  _getKichHoatBaoHanh,
  _getMaDaiLyBan,
  _getTrungTamBaoHanh,
  _taoBaoHanhWeb,
  _getTenSanPham,
  _getDemDanhSachPhieuBaoHanh,
  _getDanhSachPhieuBaoHanh,
  _getYeuCauDetail,
  _uploadFile,
  _doimatkhau,
  _addFeedBack,
  _UpdateImageBaoHanh,
  _listImageTraCuu
} from "../../api/baoHanh"
import { 
  GET_CATEGORY, 
  GET_CITY, 
  GET_DISTRICT, 
  GET_WARD,
  GET_THUONGHIEU,
  GET_PRODUCT_ERP,
  CHECK_BAO_HANH,
  ADD_BAO_HANH,
  TRA_CUU_BAO_HANH,
  GET_PRODUCTNAME,
  GET_KICHHOATBAOHANH,
  GET_MADAILYBAN,
  GET_TRUNGTAMBAOHANH,
  GET_TAOBAOHANHWEB,
  GET_TENSANPHAM,
  GET_DemDanhSachPhieuBaoHanh,
  GET_DanhSachPhieuBaoHanh,
  GET_YEUCAUDETAIL,
  UPLOAD_FILE,
  DOIMATKHAU,
  ADDFEEDBACK,
  GET_LOGIN,
  CAPNHAT_IMAGEBAOHANH,
  LISTIMAGECAPNHAT
} from "../type"
import { _LoginwithDaily } from "../../api/auth"

export const login = (body) => async dispatch => {
  try {
    _LoginwithDaily(body).then(function(res) {
     
      return dispatch({type: GET_LOGIN, data: res})
    })
  } catch (error) {
    return error;
  }
}

export const getCategory = () => async dispatch => {
  try {
    _getCategory().then(function(res) {
      return dispatch({type: GET_CATEGORY, data: res})
    })
  } catch (error) {
    return error;
  }
}

export const TaoBaoHanhWeb = (body) => async dispatch => {
  try {
    return _taoBaoHanhWeb(body)
    .then(res => {
      dispatch({ type: GET_TAOBAOHANHWEB, data: res });
      return res; 
    })
    .catch(error => {
      return error.message;
    });
  } catch (error) {
    return error;
  }
}


export const getProductName = (serial,manhomhang) => async dispatch => {
  try {
    _getProductName(serial,manhomhang).then(function(res) {
      return dispatch({type: GET_PRODUCTNAME, data: res})
    })
  } catch (error) {
    return error;
  }
}

export const getListTrungTamBaoHanh = () => async dispatch => {
  try {
    _getTrungTamBaoHanh().then(function(res) {
      return dispatch({type: GET_TRUNGTAMBAOHANH, data: res})
    })
  } catch (error) {
    return error;
  }
}


export const kichHoatBaoHanh = (body) => async dispatch => {
  try {
    _getKichHoatBaoHanh(body).then(function(res) {
      return dispatch({type: GET_KICHHOATBAOHANH, data: res})
    })
  } catch (error) {
    return error;
  }
}

export const getTenSanPham = (mahang) => async dispatch => {
  try {
    _getTenSanPham(mahang).then(function(res) {
      return dispatch({type: GET_TENSANPHAM, data: res})
    })
  } catch (error) {
    return error;
  }
}

export const getDemDanhSachPhieuBaoHanh = (body) => async dispatch => {

  try {
    return _getDemDanhSachPhieuBaoHanh(body)
    .then(res => {
      dispatch({ type: GET_DemDanhSachPhieuBaoHanh, data: res });
      return res; 
    })
    .catch(error => {
      return error.message;
    });
  } catch (error) {
    return error;
  }



 
}

export const getDanhSachPhieuBaoHanh = (body) => async dispatch => {

  try {
    return _getDanhSachPhieuBaoHanh(body)
    .then(res => {
      dispatch({ type: GET_DanhSachPhieuBaoHanh, data: res });
      return res; 
    })
    .catch(error => {
      return error.message;
    });
  } catch (error) {
    return error;
  }

 

}
export const getYeuCauDetail = (sochungtu) => async dispatch => {
  try {
    _getYeuCauDetail(sochungtu).then(function(res) {
      return dispatch({type: GET_YEUCAUDETAIL, data: res})
    })
  } catch (error) {
    return error;
  }
}


export const getThuongHieu = (id) => async dispatch => {
  try {
    _getThuonghieu(id).then(function(res) {
      return dispatch({type: GET_THUONGHIEU, data: res})
    })
  } catch (error) {
    return error;
  }
}


export const getCity = () => async dispatch => {
  try {
    _getCity().then(function(res) {
      return dispatch({ type: GET_CITY, data: res })
    })
  } catch (error) {
    return error;
  }
}

export const getMaDaiLyBan = (id) => async dispatch => {
  try {
    _getMaDaiLyBan(id).then(function(res) {
      return dispatch({ type: GET_MADAILYBAN, data: res })
    })
  } catch (error) {
    return error;
  }
}


export const getDistrict = (id) => async dispatch => {
  try {
    _getDistrict(id).then( function(res){
      return dispatch({type: GET_DISTRICT, data: res})
    })
  } catch (error) {
    return error;
  }
}

export const getWard = (districtId) => async dispatch => {
  try {
    _getWard(districtId).then(function(res) {
      return dispatch({ type:  GET_WARD, data: res })
    })
  } catch (error) {
    return error;
  }
}

export const getProductErp = (params) => async dispatch => {
  try {
    _getProductErp(params).then(function(res) {
      return dispatch({ type: GET_PRODUCT_ERP, data: res })
    })
  } catch (error) {
    return error;
  }
}

export const checkBaoHanh = (params) => async dispatch => {
  try {
    _checkBaoHanh(params).then(function(res) {
      return dispatch({ type: CHECK_BAO_HANH, data: res })
    })
  } catch (error) {
    return error;
  }
}

export const addBaoHanh = (body) => async dispatch => {
  try {
    _addBaoHanh(body).then(function(res) {
      return dispatch({ type: ADD_BAO_HANH, data: res })
    })
  } catch (error) {
    return error;
  }
}

export const traCuuBaoHanh = (params) => async dispatch => {
  try {
    _traCuuBaoHanh(params).then(function(res) {
      return dispatch({ type: TRA_CUU_BAO_HANH, data: res })
    })
  } catch (error) {
    return error;
  }
}


export const UploadFile = (sochungtu, fileList) => async dispatch => {
  
  try {
   const response = await _uploadFile(sochungtu,fileList)
      return response
  } catch (error) {
    return error;
  }
}
export const updateImageBaoHanh = (sochungtu,filename) => async dispatch => {
  try {
    _UpdateImageBaoHanh(sochungtu,filename).then(function(res) {
      return dispatch({ type: CAPNHAT_IMAGEBAOHANH, data: res})
    })
  } catch (error) {
    return error;
  }
}
export const listImageTraCuu = (params) => async dispatch => {
  try {
    _listImageTraCuu(params).then(function(res) {
      return dispatch({ type: LISTIMAGECAPNHAT, data: res})
    })
  } catch (error) {
    return error;
  }
}

export const DoiMatKhau = ( fileList) => async dispatch => {
  try {

    _doimatkhau(fileList).then(function(res) {
      return dispatch({ type: DOIMATKHAU, data: res })
    })
  } catch (error) {
    return error;
  }
}

export const AddFeedback = (body) => async dispatch => {
  try {

    _addFeedBack(body).then(function(res) {
      return dispatch({ type: ADDFEEDBACK, data: res })
    })
  } catch (error) {
    return error;
  }
}
