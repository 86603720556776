export const GET_LOGIN = 'GET_LOGIN'

export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_CITY = 'GET_CITY'
export const GET_DISTRICT = 'GET_DISTRICT'
export const GET_WARD = 'GET_WARD'
export const GET_THUONGHIEU = 'GET_THUONGHIEU'
export const GET_PRODUCT_ERP = 'GET_PRODUCT_ERP'
export const CHECK_BAO_HANH = 'CHECK_BAO_HANH'
export const ADD_BAO_HANH = 'ADD_BAO_HANH'
export const TRA_CUU_BAO_HANH = 'TRA_CUU_BAO_HANH'
export const GET_PRODUCTNAME = 'GET_PRODUCTNAME'
export const GET_KICHHOATBAOHANH = 'GET_KICHHOATBAOHANH'
export const GET_MADAILYBAN = 'GET_MADAILYBAN'
export const GET_TRUNGTAMBAOHANH = 'GET_TRUNGTAMBAOHANH'
export const GET_TAOBAOHANHWEB = 'GET_TAOBAOHANHWEB'
export const GET_TENSANPHAM = 'GET_TENSANPHAM'
export const GET_DemDanhSachPhieuBaoHanh = 'GET_DemDanhSachPhieuBaoHanh'
export const GET_DanhSachPhieuBaoHanh = 'GET_DanhSachPhieuBaoHanh'
export const GET_YEUCAUDETAIL = 'GET_YEUCAUDETAIL'


export const SET_LOADING = 'SET_LOADING'
export const SET_CAPTCHA = 'SET_CAPTCHA'

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const DOIMATKHAU = 'DOIMATKHAU'
export const ADDFEEDBACK = 'ADDFEEDBACK'
export const CAPNHAT_IMAGEBAOHANH = 'CAPNHAT_IMAGEBAOHANH'
export const LISTIMAGECAPNHAT = 'LISTIMAGECAPNHAT'




