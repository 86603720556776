import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { TagsOutlined } from "@ant-design/icons";
import imageBanner from "../../assets/image/L12.jpg"
import imageBanner1 from "../../assets/image/L13.jpg"
import { LoadingOutlined } from '@ant-design/icons';
import Captcha from "../captcha/index"
import {
  _guiYeuCauBaoHanh,
  _checkBaoHanh,
  _addBaoHanh,
} from "../../api/baoHanh";
import {
  getCity,
  getDistrict,
  getWard,

  getProductName,
  getListTrungTamBaoHanh,
  TaoBaoHanhWeb,
  getTenSanPham,
  getMaDaiLyBan,
  UploadFile,
  updateImageBaoHanh,
} from "../../redux/category/action";
import {
  Table,
  Spin,
  Space,
} from "antd";

import { _kichHoatBaoHanh, _getBaoHanh } from "../../api/baoHanh";

import { ceil, findIndex, result } from "lodash";

import pdfFile from "../../assets/pdf/chinh-sach-dich-vu-giga.pdf";

import { compareDatewithNow, formatDate } from "../../helper/convertDate";
import { toast } from "react-toastify";
import { setLoading } from "../../redux/loading/action";



const YeuCau = () => {
  const dispatch = useDispatch();
  const resultMaDaiLyBan = useSelector((store) => store.categories.getMaDaiLyBan)
  const { register, handleSubmit, watch, setValue } = useForm({ shouldUseNativeValidation: true });
  const [thanhphoModal, setThanhphoModal] = useState(false);
  const [checktensanpham, setCheckTenSanPham] = useState(false);
  const [textErrorMessage, setTextErrorMessage] = useState("");
  const [textErrorMessageMaDaily, setTextErrorMessageMaDaily] = useState("");
  const [filterText, setFilterText] = useState('');
  const [tenThuongHieu, setTenThuongHieu] = useState("");
  const [productname, setProductname] = useState("");
  const [maDailyBan, setMaDailyBan] = useState("");

  const [serial, setSerial] = useState("");
  const listCity = useSelector((store) => store.categories.listCity);
  const listDistrict = useSelector((store) => store.categories.listDistrict);
  const listThuongHieu = useSelector((store) => store.categories.listThuongHieu)
  const listWard = useSelector((store) => store.categories.listWard);
  const listTTBH = useSelector((store) => store.categories.getTrungTamBaoHanh);

  const tensanpham = useSelector((store) => store.categories.getProductName)
  const tensanphamkhachnhap = useSelector((store) => store.categories.getTenSanPham)
  const [filteredCities, setFilteredCities] = useState(listCity);
  const [city, setCity] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [district, setDistrict] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [ward, setWard] = useState(null);
  const [wardId, setWardId] = useState(null);
  const taoBaoHanhWeb = ((store) => store.categories.getTaoBaoHanhWeb)
  const uploadFiles = ((store) => store.categories.UploadFile)
  const updateImageBaoHanhs = ((store) => store.categories.updateImageBaoHanh);
  const [resultTaoBaoHanhWeb, setResultTaoBaoHanhWeb] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const [formData, setFormData] = useState({});
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setisLoading] = useState(false);
  const [isCheckSeri, setIsCheckSeri] = useState(false);

  const role_user = watch("ROLE_USER", null)
  const checknoimuahang = watch("NOI_MUA_HANG", null)
  const checkaddress = watch("GUI_HANG_VE_DIA_CHI", null)
  const IS_SUA_CHUA_CO_PHI = watch("IS_SUA_CHUA_CO_PHI", null)
  const TEN_SAN_PHAM_KHACH_NHAP = watch("TEN_SAN_PHAM_KHACH_NHAP", null)

  const captcha = useSelector((store) => store.loading.isCaptcha);
  const [isCapcha, setIsCapcha] = useState(captcha)

  const [listFileVideo, setListFleVideo] = useState([]);
  const [listDetailFileVideo, setListDetailFileVideo] = useState("");

  useEffect(() => {
    setIsCapcha(captcha)
  }, [captcha]);




  const columnsSanpham = [
    {
      title: "",
      dataIndex: "TEN_HANG",
      key: "TEN_HANG",
      render: (file_path_PI, record, index) => {
        return (
          <input
            value={record.TEN_HANG}
            name="TEN_HANG"
            onClick={() => {
              setProductname(record.TEN_HANG);
              setCheckTenSanPham(false);
              setIsCheckSeri(record.CAN_LAY_SERIES);
            }}
            style={{
              width: '100%',
              border: "none",
              background: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
            readOnly
          />
        );
      },
    },
  ];
  const columnsCity = [

    {
      title: "",
      dataIndex: "ProvinceName",
      key: "ProvinceName",
      render: (file_path_PI, record, index) => {
        return (
          <input
            value={record.ProvinceName}
            name="ProvinceName"
            onClick={() => {
              setFilterText(record.ProvinceName);
              setCityId(record.ProvinceID)
              setThanhphoModal(false)
            }}
            style={{
              width: '100%',
              border: "none",
              background: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
            readOnly
          />
        );
      },
    },
  ];
  useEffect(() => {

    if (resultMaDaiLyBan.length > 0) {
      setTextErrorMessageMaDaily("")
      setCurrentTab(3);

    } else if (maDailyBan == "") {
      setTextErrorMessageMaDaily("")
    }
    else {
      setTextErrorMessageMaDaily("Mã đại lý không chính xác, vui lòng kiểm tra lại hoặc liên hệ nhân viên bán hàng để được trợ giúp.")
    


    }
  }, [resultMaDaiLyBan, setTextErrorMessageMaDaily]);
  

  useEffect(() => {

    setValue("TEN_SAN_PHAM", tensanpham[0]?.TEN_HANG ?? "");
    setValue("HAN_BAO_HANH", formatDate(tensanpham[0]?.NGAY_HET_BAO_HANH ?? ""));
    setValue("TEN_SAN_PHAM_KHACH_NHAP", productname);

    setValue("TINH_THANH_PHO", filterText);
    setValue("MA_HANG", tensanpham[0]?.MA_HANG ?? "");
  }, [tensanpham[0]?.TEN_HANG, tensanpham[0]?.NGAY_HET_BAO_HANH, filterText, tensanpham[0]?.MA_HANG, resultTaoBaoHanhWeb, productname]);


  useEffect(() => {
    dispatch(getCity());
    dispatch(getListTrungTamBaoHanh());

  }, []);


  useEffect(() => {
    if (cityId != null) {
      dispatch(getDistrict(cityId));
    }
  }, [cityId]);

  useEffect(() => {
    if (districtId != null) {
      dispatch(getWard(districtId));
    }
  }, [districtId]);


  useEffect(() => {
    const timer = setTimeout(() => {
      if (productname) {
        dispatch(getTenSanPham(productname));
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [productname]);
  useEffect(() => {
    findIndex(listCity, (item) => {
      if (item.ProvinceName == city) {
        setCityId(item.ProvinceID);
        setDistrict("");
      }
    });
  }, [city]);

  useEffect(() => {
    findIndex(listDistrict, (item) => {
      if (item.DistrictName == district) {
        setDistrictId(item.DistrictID);
      }
    });
  }, [district]);

  useEffect(() => {
    findIndex(listWard, (item) => {
      if (item.WardName == ward) {
        setWardId(item.DistrictID);
      }
    });
  }, [ward]);
  const onFocus = async (event) => {

    var name = event.target.name;
    var value = event.target.value;

    if (name == "MA_KHACH_HANG_DAI_LY") {
      setisLoading(true)
    }
  }


  const onBlur = async (event) => {

    var name = event.target.name;
    var value = event.target.value;
    if (name == "TINH_THANH_PHO") {
      setTimeout(() => {
        setThanhphoModal(false);
      }, 200)
    }

    if (name == "TEN_SAN_PHAM_KHACH_NHAP") {
      setTimeout(() => {
        setCheckTenSanPham(false);
      }, 200)
    }
    if (name == "MA_KHACH_HANG_DAI_LY") {
      setisLoading(false)
      setMaDailyBan(value);
  
     
    }
  }
  const onChange = async (event) => {
    var value = event.target.value;
    var name = event.target.name;
    if (name == "THUONG_HIEU") {
      setTextErrorMessage("")
      await setTenThuongHieu(value);

    }
    if (name == "TEN_SAN_PHAM_KHACH_NHAP") {

      setProductname(value)

    }
    if (name == "ROLE_USER") {
      await setTenThuongHieu(value);
    }
    if (name == "SERIAL") {
      setTextErrorMessage("")

      await setSerial(value)

    }
    if (name == "TINH_THANH_PHO") {
      setFilterText(value);
      filterCities(value);

    }

  }
  const tracuuSerial = async () => {
    if (serial != undefined && tenThuongHieu != undefined) {
      dispatch(getProductName(serial, tenThuongHieu));
    }
  }


  const filterCities = (criteria) => {

    const filtered = listCity.filter(city => city.ProvinceName.toLowerCase().includes(criteria.toLowerCase()));
    setFilteredCities(filtered);

  };

  
  const handleChangeDistrict = (e) => {
    setDistrict(e.target.value);
  };

  const handleChangeWard = (e) => {
    setWard(e.target.value);
  };

  const onSubmitForm2 = async data => {
    setFormData((prevData) => ({ ...prevData, ...data }));

    const response = await dispatch(getMaDaiLyBan(maDailyBan));
  
   
  }
  const onSubmitForm3 = async data => {
    if (tenThuongHieu !== "Thương hiệu khác" && (tensanpham[0]?.TEN_HANG == undefined || compareDatewithNow(formatDate(tensanpham[0]?.NGAY_HET_BAO_HANH)))) {
      if (data.IS_SUA_CHUA_CO_PHI == "true" && data.TEN_SAN_PHAM_KHACH_NHAP != "") {
        setFormData((prevData) => ({ ...prevData, ...data }));
        setCurrentTab(4);
      } else {
        setTextErrorMessage("Sản phẩm của Quý khách chưa đủ điều kiện bảo hành, vui lòng chọn Tôi cần sửa chữa dịch vụ hoặc liên hệ nhân viên bán hàng / hotline 1900.633.870 để được hỗ trợ")
      }
    } else {
      setFormData((prevData) => ({ ...prevData, ...data }));
      setCurrentTab(4);
    }

  }

  const onSubmitForm4 = async data => {
    await dispatch(setLoading(true));
    const updatedFormData = { ...formData, ...data };
    await setFormData(updatedFormData);
    const response = await dispatch(TaoBaoHanhWeb(updatedFormData));
    await dispatch(setLoading(false));
    setResultTaoBaoHanhWeb(response);
    if (response.STATUS === true) {
      try {
        for (let i = 0; i < listFileVideo.length; i++) {
          const file = listFileVideo[i];
          const responseimage = await dispatch(UploadFile(response.SO_CHUNG_TU, {
            files: file,
          }));

          const resultUpdate = await dispatch(updateImageBaoHanh(response.SO_CHUNG_TU, responseimage[0].linkFile));
        }
      } catch (e) {
        toast.error("Vui lòng chọn lại tệp tin");
      }
      setCurrentTab(5);
    } else if (response.STATUS === false) {

      toast.error(response.MESSAGE);
    }
  }

  const onChangeFile = async (e) => {
    const selectedFile = e.target.files;
    setListFleVideo(selectedFile);
    

  };
  
  useEffect(() => {
    setListDetailFileVideo(Array.from(listFileVideo).map(file => ({
      name: file.name,
      size: (file.size / 1024).toFixed(2)
    })));
  }, [listFileVideo]);



  return (
    <>
      <div className="container chinh-sach mt-6">
        <div className="chinh-sach-bao-hanh-chung mt-6">

          {/* Tab 1 */}
          {currentTab == "1" && (
            <div className="Tab 1">
              <h4 className="fw-bold text-center title_important ">TẠO PHIẾU YÊU CẦU BẢO HÀNH - SỬA CHỮA ONLINE</h4>
              <div className="content row " >

                <div className="col-sm-8" style={{ paddingRight: '2px' }}>
                  <ul className="list-unstyled" style={{ marginTop: '20px' }}>
                    <li className="fw-bold mb-2">
                      <p className="title_important">Quý khách đang có nhu cầu Bảo hành - Sửa chữa thiết bị gia dụng?</p>
                    </li>
                    <li className="mb-2">
                      <p>Hãy tạo yêu cầu bảo hành online và gửi chuyển phát sản phẩm cho chúng tôi theo hướng dẫn dưới đây</p>
                    </li>
                    <li className="mb-2">
                      <p>Hoặc mang trực tiếp sản phẩm tới các Trạm bảo hành của chúng tôi theo <a className="daily_question" style={{ textDecoration: 'underline' }} target="_blank" href="/tram-bao-hanh/ha-noi"> các địa chỉ sau</a>. </p>
                    </li>
                    <li className="mb-2">
                      <p>Trung tâm bảo hành GIGACARE luôn sẵn sàng phục vụ Quý khách!</p>
                    </li>
                  </ul>

                  <ul className="list-unstyled" style={{ marginTop: '30px' }}>
                    <li className="fw-bold mb-2">
                      <p>Xin Quý khách lưu ý:</p>
                    </li>
                    <li className="mb-2">
                      <p>📌 Vui lòng đọc kỹ Chính sách bảo hành sản phẩm của chúng tôi <a href="/chinh-sach-bao-hanh" className="daily_question" style={{ textDecoration: 'underline' }} target="_blank">tại đây </a>.</p>
                    </li>
                    <li className="mb-2">
                      <p>📌 GIGA.vn có cung cấp dịch vụ sửa chữa theo nhu cầu, Quý khách vui lòng tham khảo biểu phí dịch vụ <a href={pdfFile} className="daily_question" style={{ textDecoration: 'underline' }} target="_blank">tại đây </a>.</p>
                    </li>
                    <li className="mb-2">
                      <p>📌 Mọi yêu cầu hỗ trợ, tư vấn vui lòng liên hệ hotline 1900.633.870 (8-21h hằng ngày).</p>
                    </li>
                  </ul>

                  <ul className="list-unstyled" style={{ marginTop: '30px' }}>
                    <li className="fw-bold mb-2">
                      <p>Yêu cầu bảo hành - sửa chữa online của GIGACARE có ưu điểm gì?</p>
                    </li>
                    <li className="mb-2">
                      <p><TagsOutlined style={{ color: '#2289cb', fontSize: '18px' }} /> Tiết kiệm thời gian di chuyển </p>
                    </li>
                    <li className="mb-2">
                      <p><TagsOutlined style={{ color: '#2289cb', fontSize: '18px' }} /> Cập nhật tiến độ sửa chữa sản phẩm online nhanh chóng</p>
                    </li>
                    <li className="mb-2">
                      <p><TagsOutlined style={{ color: '#2289cb', fontSize: '18px' }} /> Đội ngũ CSKH online luôn sẵn sàng phục vụ.</p>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-2 csbh_image_banner ">
                  <img alt="Hình ảnh" style={{ height: '20rem', marginTop: '46px', marginLeft: '26px' }} className="h-48 w-full object-cover md:h-full md:w-48" src={imageBanner} />
                </div>

              </div>
              <div className=' col-sm-12 mb-4 mt-2 d-flex justify-content-center'>
                <button
                  className='giga-kich-hoat-btn' style={{ padding: '15px', textTransform: 'uppercase' }} onClick={() => {
                    setCurrentTab(2)

                  }}> Bắt đầu tạo phiếu </button>
              </div>
            </div>
          )}
          {/* ------ */}
          {/* Tab 2 */}
          <form onSubmit={handleSubmit(onSubmitForm2)}>
            {currentTab == "2" && (

              <div className="Tab 2">
                <h4 className="fw-bold text-center title_important ">TẠO PHIẾU YÊU CẦU BẢO HÀNH - SỬA CHỮA ONLINE</h4>
                <div className="content row progress_step"  >
                  <div className="col-3" id="comment_bubblefirst">Thông tin liên hệ</div>
                  <div id="comment_bubble" className="col-3 disable">Thông tin sản phẩm</div>
                  <div id="comment_bubble" className="col-3 disable">Thông tin vận chuyển</div>

                </div>
                <br />
                <div className="content row " >

                  <div className="col-sm-10">
                    <ul className="list-unstyled">
                      <li className="fw-bold mb-2">
                        <p className="title_important_header">1. THÔNG TIN LIÊN HỆ</p>
                      </li>
                      <li className="mb-2 title_important">
                        <p>Bạn là người sử dụng sản phẩm hay đơn vị bán lẻ?<span className="require">*</span></p>
                      </li>
                    </ul>

                    <div onClick={() => setDisableButton(true)} class="mb-4 flex items-center container_radio">
                      <input
                        {...register("ROLE_USER")}
                        id="IS_NGUOI_DUNG"
                        type="radio"
                        required
                        value="Tôi là người sử dụng sản phẩm"

                        class="bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label
                        for="IS_NGUOI_DUNG"
                        class="ms-2"
                      >
                        Tôi là người sử dụng sản phẩm
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                        <div className="col-md-3 responsive_checkbox" style={{ display: 'flex', alignItems: 'center', marginRight: '4px' }}>
                          <input
                            {...register("ROLE_USER")}
                            id="other"
                            type="radio"

                            required
                            value="Tôi là đơn vị bán lẻ"
                            className=" h-4 w-4"
                          />
                          <label for="other" class=" ms-2 text-sm font-medium">
                            Tôi là đơn vị bán lẻ:{" "}
                          </label>
                        </div>
                        <div className="col-md-9 responsive_checkbox" style={{ position: 'relative' }}>
                          <input
                            type="text"
                            {...register("MA_KHACH_HANG_DAI_LY")}
                            disabled={role_user != "Tôi là đơn vị bán lẻ"}
                            required={role_user == "Tôi là đơn vị bán lẻ"}
                            placeholder="Vui lòng nhập mã KHGD"
                            onBlur={onBlur}
                            onFocus={onFocus}


                            style={{ border: `1px solid ${(maDailyBan === '') ? '#dee2e6' : (textErrorMessageMaDaily === '' ? 'green' : 'red')}` }}
                            className="input_yeucau"
                          />
                          <div style={{ position: 'absolute', top: 5, right: 10, display: (loading ? '' : 'none') }}> <Space> <Spin indicator={<LoadingOutlined spin />} size="small" /> </Space>  </div>
                          <span className="require">{textErrorMessageMaDaily}  </span>

                        </div>

                      </div>

                    </div>
                    <br />

                    <div className="row">
                      <div className="col-md-12 my-3 responsive_input" style={{ display: 'flex' }}>
                        <div className="col-md-3 responsive_checkbox" style={{ marginRight: '4px' }}>

                          <label for="other" class=" ms-2 text-sm font-medium title_important">
                            Họ và tên của bạn:<span className="require">*</span>
                          </label>
                        </div>
                        <div className="col-md-9 responsive_checkbox">
                          <input
                            type="text"

                            {...register("HO_VA_TEN", { required: "Vui lòng nhập câu trả lời." })}
                            placeholder="Vui lòng nhập câu trả lời"
                            className="input_yeucau"

                          />
                        </div>
                      </div>
                      <div className="col-md-12 my-3 responsive_input" style={{ display: 'flex' }}>
                        <div className="col-md-3 responsive_checkbox" style={{ marginRight: '4px' }}>

                          <label for="other" class=" ms-2 text-sm font-medium title_important">
                            Số điện thoại liên hệ:<span className="require">*</span>
                          </label>
                        </div>
                        <div className="col-md-9 responsive_checkbox">
                          <input
                            type="text"
                            maxLength={10}

                            {...register("SO_DIEN_THOAI", {
                              required: "Vui lòng nhập số điện thoại.",
                              pattern: {
                                value: /^\d{10}$/,
                                message: "Vui lòng nhập đúng định dạng."
                              },
                            })}
                            placeholder="Vui lòng nhập câu trả lời"
                            className="input_yeucau"

                          />
                        </div>
                      </div>
                      <div className="col-md-12 my-3 responsive_input" style={{ display: 'flex' }}>
                        <div className="col-md-3 responsive_checkbox" style={{ marginRight: '4px' }}>

                          <label for="other" class=" ms-2 text-sm font-medium title_important">
                            Email của bạn:
                          </label>
                        </div>
                        <div className="col-md-9 responsive_checkbox">
                          <input
                            type="email"

                            {...register("EMAIL")}
                            placeholder="Vui lòng nhập câu trả lời"
                            className="input_yeucau"

                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 prestep_button">
                      <button
                        className="hover_button_tracuu mb-3 rounded border bg-transparent px-4 py-2  font-semibold rounded-md  shadow-lg "
                        style={{ marginRight: '5px' }}
                        onClick={() => setCurrentTab(1)}
                      >
                        Quay lại
                      </button>
                      <button type="submit" disabled={!disableButton}
                        className="hover_button_tracuu mb-3 rounded  border  bg-transparent px-4 py-2  font-semibold shadow-lg hover:border-transparent"
                      >
                        Tiếp
                      </button>
                    </div>


                  </div>
                  <div className="col-sm-2 csbh_image_banner ">
                    <img alt="Hình ảnh" style={{ height: '20rem' }} className="h-48 w-full object-cover md:h-full md:w-48" src={imageBanner1} />
                  </div>



                </div>

              </div>

            )}
          </form>
          {/* ------ */}

          {/* Tab 3 */}
          <form onSubmit={handleSubmit(onSubmitForm3)}>
            {currentTab == "3" && (
              <div className="Tab 3">
                <h4 className="fw-bold text-center title_important ">TẠO PHIẾU YÊU CẦU BẢO HÀNH - SỬA CHỮA ONLINE</h4>
                <div className="content row progress_step"  >
                  <div id="comment_bubblefirst" className="col-md-3">Thông tin liên hệ</div>
                  <div id="comment_bubble" className="col-md-3">Thông tin sản phẩm</div>
                  <div id="comment_bubble" className="disable col-md-3">Thông tin vận chuyển</div>

                </div>
                <div className="content row " >

                  <div className="col-sm-10">
                    <ul className="list-unstyled">
                      <li className="fw-bold mb-2">
                        <p className="title_important_header">2. THÔNG TIN SẢN PHẨM</p>
                      </li>

                    </ul>
                    <div className="col-md-12 responsive_input">
                      <div className="title_yeucau col-md-12 ">Sản phẩm của bạn thuộc thương hiệu nào?<span className="require">*</span></div>

                      <div className="my-2 col-md-12">
                        <form className="mx-auto max-w-sm">
                          <select
                            {...register("THUONG_HIEU")}
                            id="countries"
                            style={{ width: "100%" }}
                            onChange={onChange}
                            className=" w-full border p-2 "
                          >
                            <option value=""></option>
                            <option value="Ecovacs">Ecovacs</option>
                            <option value="Tineco">Tineco</option>
                            <option value="Kingsmith">Kingsmith</option>
                            <option value="New Widetech">New Widetech</option>
                            <option value="Mibro">Mibro</option>
                            <option value="Imilab">Imilab</option>
                            <option value="Xiaomi">Xiaomi</option>
                            <option value="Cuckoo">Cuckoo</option>
                            <option value="Thương hiệu khác">Thương hiệu khác</option>
                          </select>
                        </form>
                      </div>

                    </div>
                    <br />
                    {tenThuongHieu === "" ? null : (tenThuongHieu === "Thương hiệu khác") ? (
                      <>
                        {role_user === "Tôi là người sử dụng sản phẩm" && (
                          <>
                            <div className="col-md-12 form-inline title_yeucau" >Bạn mua sản phẩm này theo kênh thương mại nào? <span className="require">*</span> </div> <br />
                            <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                              <div className="col-md-5 responsive_checkbox" style={{ display: 'flex' }}>
                                <input
                                  {...register("NOI_MUA_HANG")}
                                  id="showroomgiga"
                                  type="radio"
                                  required
                                  value="Tôi mua tại showroom/website của GIGA.vn"
                                  class=" h-4 w-4"
                                />
                                <label for="showroomgiga" class="ms-2 text-sm font-medium">
                                  Tôi mua tại showroom/website của GIGA.vn{" "}
                                </label>
                              </div>
                              <div className="col-md-7 responsive_checkbox">
                                <input
                                  type="text"
                                  {...register("SDT_MUA_HANG_SHOWROOM")}
                                  disabled={checknoimuahang != "Tôi mua tại showroom/website của GIGA.vn"}
                                  required={checknoimuahang == "Tôi mua tại showroom/website của GIGA.vn"}
                                  placeholder="Vui lòng nhập Số điện thoại mua hàng*"
                                  className="input_yeucau"

                                />
                              </div>
                            </div> <br />
                            <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                              <div className="col-md-5 responsive_checkbox" style={{ display: 'flex' }}>
                                <input
                                  {...register("NOI_MUA_HANG")}
                                  id="tmdt"
                                  required
                                  type="radio"

                                  value="Tôi mua qua sàn Thương mại điện tử"
                                  class=" h-4 w-4"
                                />
                                <label for="tmdt" class=" ms-2 text-sm font-medium">
                                  Tôi mua qua sàn Thương mại điện tử{" "}
                                </label>
                              </div>
                              <div className="col-md-7 responsive_checkbox">
                                <input
                                  type="text"
                                  {...register("MA_DON_HANG_TMDT")}
                                  disabled={checknoimuahang != "Tôi mua qua sàn Thương mại điện tử"}
                                  required={checknoimuahang == "Tôi mua qua sàn Thương mại điện tử"}
                                  placeholder="Vui lòng nhập mã đơn hàng sàn Shopee, Lazada, Tiktok,...*"
                                  className="input_yeucau"

                                />
                              </div>
                            </div> <br />
                            <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                              <div className="col-md-5 responsive_checkbox" style={{ display: 'flex' }}>
                                <input
                                  {...register("NOI_MUA_HANG")}
                                  id="matmdt"
                                  required
                                  type="radio"

                                  value="Tôi mua qua đại lý của GIGA.vn"
                                  class=" h-4 w-4"
                                />
                                <label for="matmdt" class=" ms-2 text-sm font-medium">
                                  Tôi mua qua đại lý của GIGA.vn{" "}
                                </label>
                              </div>
                              <div className="col-md-7 responsive_checkbox">
                                <input
                                  type="text"
                                  {...register("MA_DAI_LY")}
                                  disabled={checknoimuahang != "Tôi mua qua đại lý của GIGA.vn"}
                                  required={checknoimuahang == "Tôi mua qua đại lý của GIGA.vn"}
                                  placeholder="Vui lòng nhập mã đại lý*"
                                  className="input_yeucau"

                                />
                              </div>
                            </div>  <br />

                            <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                              <div className="col-md-8 responsive_checkbox">
                                <input
                                  {...register("NOI_MUA_HANG")}
                                  id="othergift"
                                  required
                                  type="radio"
                                  value="Tôi được tặng/Tôi không rõ/Tôi muốn sửa dịch vụ"
                                  class=" h-4 w-4"
                                />
                                <label for="othergift" class=" ms-2 text-sm font-medium">
                                  Khác: Tôi được tặng/Tôi không rõ/Tôi muốn sửa dịch vụ....{" "}
                                </label>
                              </div>

                            </div> <br />
                          </>
                        )}
                        {(checknoimuahang == null && role_user == "Tôi là người sử dụng sản phẩm") ? (<> </>) :
                          (checknoimuahang != "Tôi được tặng/Tôi không rõ/Tôi muốn sửa dịch vụ" || role_user == "Tôi là đơn vị bán lẻ") ? (<>

                            <div className="col-md-12" style={{ display: 'flex', alignItems: 'center' }}>
                              <div className="col-md-5">

                                <label for="other" class=" ms-2 text-sm font-medium title_important">
                                  Hãy chọn Tên sản phẩm <span className="require"> *</span>:{" "}
                                </label>
                              </div>
                              <div className="col-md-7">
                                <div style={{ position: 'relative' }}>
                                  <input onClick={() => setCheckTenSanPham(!checktensanpham)}
                                    type="text"
                                    className="input_yeucau"
                                    {...register("TEN_SAN_PHAM_KHACH_NHAP", { required: "Vui lòng nhập câu trả lời." })}
                                    value={productname}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                  />
                                  {checktensanpham && (
                                    <Table
                                      columns={columnsSanpham}
                                      dataSource={tensanphamkhachnhap}
                                      pagination={false}
                                      scroll={{ y: 300 }}

                                      style={{
                                        position: "absolute", top: 50, zIndex: '1', border: '1px solid #dee2e6'
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>


                          </>) : (checknoimuahang == "Tôi được tặng/Tôi không rõ/Tôi muốn sửa dịch vụ") ? (
                            <>
                              <div className="col-md-12" style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="col-md-5">
                                  <label for="other" class=" ms-2 text-sm font-medium title_important">
                                    Hãy nhập Tên sản phẩm<span className="require">*</span>:{" "}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="text"
                                    {...register("TEN_SAN_PHAM_KHACH_NHAP", { required: "Vui lòng nhập câu trả lời." })}

                                    className="input_yeucau"

                                  />
                                </div>
                              </div> </>) : (
                            <> </>

                          )}  <br />
                        <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                          <div className="col-md-5 responsive_checkbox">

                            <label for="other" class=" ms-2 text-sm font-medium title_important">
                              Vui lòng nhập số serial nếu có:{" "}
                            </label>
                          </div>
                          <div className="col-md-7 responsive_checkbox">
                            <input
                              type="text"
                              required={checknoimuahang != "Tôi được tặng/Tôi không rõ/Tôi muốn sửa dịch vụ" && isCheckSeri == true}
                              {...register("SERIAL_KHACH_NHAP")}
                              className="input_yeucau"
                            />
                          </div>
                        </div>  <br />
                        <div className="col-md-12">
                          <div>
                            Máy của bạn đang gặp sự cố gì, hãy mô tả thật kỹ để chúng tôi hỗ trợ tốt nhất cho bạn nhé <span className="require">*</span>:
                          </div>
                          <div className="my-2">
                            <textarea

                              {...register("MO_TA_LOI_CHO_KY_THUAT", { required: "Vui lòng nhập câu trả lời." })}

                              style={{
                                height: '80px',
                                outline: "none",
                                width: "100%", borderRadius: '6px'
                              }}
                              class="border-b border-none p-2.5 text-sm "

                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div>
                            Vui lòng quay lại video mô tả lỗi sản phẩm (dung lượng dưới 500MB khoảng 1 phút) giúp kỹ thuật viên của chúng tôi hiểu rõ tình trạng máy, đưa ra phương án sửa chữa hiệu quả nhất <span className="require">*</span>:
                          </div>
                          <div className="my-2">
                            <div className="file-upload-container">
                              <label for="file-custom" className="file-upload-label">
                                <div className="file-upload-content">
                                <svg className="file-upload-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                                  <p className="file-upload-text-main">
                                    <span className="file-upload-text-bold">Click to upload</span> or drag and drop
                                  </p>
                                  {(listDetailFileVideo) && (
                                    <>
                                      {listDetailFileVideo?.map((file, index) => (
                                        <>
                                          <li className="row col-md-12">
                                            <div className="content upload col-md-9">
                                              <i className="fas fa-file-alt" />
                                              <div className="details">
                                                <span className="name">
                                                  {file.name} • Uploaded
                                                </span>
                                                <span className="size">
                                                  {file.size} KB
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-md-2" style={{display:'flex',flexDirection:'row-reverse'}}>
                                            <i className="fas fa-check" />
                                            </div>
                                          </li>
                                        </>
                                      ))}
                                    </>
                                  )}
                                </div>
                                <input id="file-custom" type="file" className="file-upload-input" onChange={onChangeFile} multiple />
                              </label>
                            </div>

                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-xl-12 responsive_serial" style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="col-xl-5 col-12 mb-3">
                            <div className="title_yeucau col-xl-12">Vui lòng nhập số serial của máy: <span className="require">*</span></div>
                            <div className=" col-12 col-xl-12">
                              <input
                                type="text"
                                {...register("SERIAL")}
                                onChange={onChange}
                                className="input_yeucau"

                                placeholder="Câu trả lời của bạn"
                              />
                            </div>

                          </div>
                          <div className="col-xl-1"> </div>
                          <div className="col-xl-3 col-12 mb-3">
                            <Captcha value={serial} />
                          </div>
                          <div className="col-xl-1"> </div>
                          <div className="col-12 col-xl-2">
                            <input type="button" onClick={() => tracuuSerial()}
                              className="hover_button_tracuu tracuu_serial rounded border  bg-transparent px-4 p-3  font-semibold rounded-md   "
                              style={{ width: '100%' }}
                              disabled={!isCapcha} value={"Tra cứu"}
                            />
                          </div>
                        </div>
                        <br /> <br />





                        <br />
                        <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                          <div className="title_yeucau col-md-4 responsive_checkbox">Tên sản phẩm</div>
                          <div className="col-md-8 responsive_checkbox">
                            <input
                              type="text"
                              {...register("TEN_SAN_PHAM")}
                              readOnly
                              className="input_yeucau"
                              value={(serial != "" ? tensanpham[0]?.TEN_HANG ?? "" : "")}
                            />
                          </div>
                        </div>  <br />

                        <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                          <div className="title_yeucau col-md-4 responsive_checkbox">Hạn bảo hành</div>
                          <div className="col-md-8 responsive_checkbox">
                            <input
                              type="text"
                              readOnly
                              {...register("HAN_BAO_HANH")}
                              className="input_yeucau"
                              value={serial != "" ? formatDate(tensanpham[0]?.NGAY_HET_BAO_HANH ?? "") : ""}
                            />
                          </div>
                        </div> <br />
                        <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                          <div className="col-md-4 responsive_checkbox" style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                              {...register("IS_SUA_CHUA_CO_PHI")}
                              id="checked-checkbox"
                              type="checkbox"
                              value="true"
                              className="bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded text-blue-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                            />
                            <label
                              htmlFor="checked-checkbox"
                              className="text-gray-900 dark:text-gray-300 ms-2 text-sm font-medium"
                            >
                              Tôi cần sửa chữa dịch vụ có phí
                            </label>
                          </div>

                          <div className="col-md-8 responsive_checkbox">
                            <input
                              type="text"
                              readOnly={tensanpham[0]?.TEN_HANG}
                              {...register("TEN_SAN_PHAM_KHACH_NHAP")}
                              onChange={onChange}
                              className="input_yeucau"
                              placeholder="Vui lòng nhập tên sản phẩm của bạn"
                            />
                          </div>
                        </div> <br />
                        <div className="require">{textErrorMessage}</div>
                        <div className="col-md-12">
                          <div className="title_yeucau">
                            Máy của bạn đang gặp sự cố gì, hãy mô tả thật kỹ để chúng tôi hỗ trợ tốt nhất cho bạn nhé <span className="require">*</span>:
                          </div>
                          <div className="my-2">
                            <textarea

                              {...register("MO_TA_LOI_CHO_KY_THUAT", { required: "Vui lòng nhập câu trả lời." })}

                              style={{
                                height: '130px',
                                outline: "none",
                                width: "100%", borderRadius: '6px'
                              }}
                              class="border-b border-none p-2.5 text-sm "

                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div>
                            Vui lòng quay lại video mô tả lỗi sản phẩm (dung lượng dưới 500MB khoảng 1 phút) giúp kỹ thuật viên của chúng tôi hiểu rõ tình trạng máy, đưa ra phương án sửa chữa hiệu quả nhất <span className="require">*</span>:
                          </div>
                          <div className="my-2">
                            <div className="file-upload-container">
                              <label for="file-custom" className="file-upload-label">
                                <div className="file-upload-content">
                                <svg className="file-upload-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                                  <p className="file-upload-text-main">
                                    <span className="file-upload-text-bold">Click to upload</span> or drag and drop
                                  </p>

                                </div>
                                <input id="file-custom" type="file" className="file-upload-input" onChange={onChangeFile} multiple />
                              </label>

                            </div>
                            <section className="uploaded-area">
                              {(listDetailFileVideo) && (
                                <>
                                  {listDetailFileVideo?.map((file, index) => (
                                    <>
                                      <li className="row col-md-12">
                                            <div className="content upload col-md-9">
                                              <i className="fas fa-file-alt" />
                                              <div className="details">
                                                <span className="name">
                                                  {file.name} • Uploaded
                                                </span>
                                                <span className="size">
                                                  {file.size} KB
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-md-2" style={{display:'flex',flexDirection:'row-reverse'}}>
                                            <i className="fas fa-check" />
                                            </div>
                                          </li>
                                    </>
                                  ))}
                                </>
                              )}
                            </section>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-md-12 prestep_button">
                      <button
                        className="hover_button_tracuu mb-3 rounded  border  bg-transparent px-4 py-2  font-semibold rounded-md  shadow-lg "
                        style={{ marginRight: '5px' }}
                        onClick={() => setCurrentTab(2)}
                      >
                        Quay lại
                      </button>
                      {(tenThuongHieu !== "") && (
                        <button type="submit"
                          className="hover_button_tracuu mb-3 rounded  border  bg-transparent px-4 py-2  font-semibold shadow-lg hover:border-transparent"

                        >
                          Tiếp
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-2 csbh_image_banner ">
                    <img alt="Hình ảnh" style={{ height: '20rem' }} className="h-48 w-full object-cover md:h-full md:w-48" src={imageBanner1} />
                  </div>

                </div>


              </div>
            )}
          </form>
          {/* ------ */}
          {/* Tab 4 */}
          <form onSubmit={handleSubmit(onSubmitForm4)}>
            {currentTab == "4" && (

              <div className="Tab 4">
                <h4 className="fw-bold text-center title_important ">TẠO PHIẾU YÊU CẦU BẢO HÀNH - SỬA CHỮA ONLINE</h4>
                <div className="content row progress_step"  >

                  <div id="comment_bubblefirst" className="col-3">Thông tin liên hệ</div>
                  <div id="comment_bubble" className="col-3">Thông tin sản phẩm</div>
                  <div id="comment_bubble" className="col-3">Thông tin vận chuyển</div>

                </div>
                <div className="content row " >

                  <div className="col-sm-10">
                    <ul className="list-unstyled">
                      <li className="fw-bold mb-2">
                        <p className="title_important_header">3. THÔNG TIN VẬN CHUYỂN</p>
                      </li>

                    </ul>
                    <div className="col-md-12 form-inline title_yeucau">Bạn muốn gửi sản phẩm đến trung tâm bảo hành nào?<span className="require">*</span></div> <br />

                    {listTTBH.map((item) => (
                      <>
                        <div className="col-md-12" style={{ display: 'flex', alignItems: 'center' }} key={item.id}>
                          <input
                            {...register("TRUNG_TAM_BAO_HANH")}
                            id={item.$id}
                            required
                            type="radio"
                            value={item.Key}
                            className="h-4 w-4"
                          />
                          <label htmlFor={item.$id} className="ms-2 text-sm font-medium">
                            {item.Value}
                          </label>

                        </div>

                        <br />

                      </>

                    ))
                    }
                    <br />

                    <div className="col-md-12 form-inline title_yeucau">Hãy sau khi hoàn tất dịch vụ, bạn muốn chúng tôi gửi trả sản phẩm về địa chỉ nào?<span className="require">*</span></div>
                    <br />

                    <div className="col-md-12" style={{ display: 'flex', alignItems: 'center' }}>

                      <input
                        {...register("GUI_HANG_VE_DIA_CHI")}
                        id="tructiep"
                        type="radio"
                        value="Tôi sẽ qua Trung tâm bảo hành nhận trực tiếp"
                        class=" h-4 w-4"
                      />
                      <label for="tructiep" class=" ms-2 text-sm font-medium">
                        Tôi sẽ qua Trung tâm bảo hành nhận trực tiếp{" "}
                      </label>

                    </div> <br />
                    <div className="col-md-12 responsive_input" style={{ display: 'flex' }}>
                      <div className="col-md-3 responsive_checkbox">
                        <input
                          {...register("GUI_HANG_VE_DIA_CHI")}
                          id="Tôi muốn gửi về địa chỉ"
                          type="radio"
                          value="Tôi muốn gửi về địa chỉ"

                        />
                        <label for="Tôi muốn gửi về địa chỉ" class=" ms-2 text-sm font-medium">
                          Tôi muốn gửi về địa chỉ: {" "}
                        </label>
                      </div>
                      {checkaddress == "Tôi muốn gửi về địa chỉ" && (
                        <>
                          <div className="col-md-12" style={{ display: 'flex' }}>
                            <div className="responsive_checkbox mr-2 col-md-3" style={{ marginRight: '5px' }}>
                              <div className="city" style={{ position: "relative" }}  >
                                <input onClick={() => setThanhphoModal(!thanhphoModal)}
                                  type="text"
                                  className="form-control"
                                  disabled={checkaddress != "Tôi muốn gửi về địa chỉ"}
                                  required={checkaddress == "Tôi muốn gửi về địa chỉ"}

                                  {...register('TINH_THANH_PHO')}
                                  placeholder="Tỉnh/Thành phố"
                                  value={filterText}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                />
                                {thanhphoModal && (
                                  <Table
                                    columns={columnsCity}
                                    dataSource={filteredCities.length == 0 ? listCity : filteredCities}
                                    pagination={false}
                                    scroll={{ y: 300 }}

                                    style={{
                                      position: "absolute", top: 50, zIndex: '1', border: '1px solid #dee2e6'
                                    }}
                                  />
                                )}



                              </div>
                            </div>

                            <div className="responsive_checkbox mr-2 col-md-3" style={{ marginRight: '5px' }}>
                              <select disabled={checkaddress != "Tôi muốn gửi về địa chỉ"} required={checkaddress == "Tôi muốn gửi về địa chỉ"} className='form-control' {...register('QUAN_HUYEN')} value={district} onChange={(e) => handleChangeDistrict(e)}>
                                <option value={''} selected disabled>Quận/Huyện</option>
                                {
                                  listDistrict && listDistrict.map((item, index) => (
                                    <option key={index} value={item.DistrictName}>{item.DistrictName}</option>
                                  ))
                                }
                              </select>


                            </div>

                            <div className="responsive_checkbox mr-2 col-md-3" style={{ marginRight: '5px' }}>

                              <select style={{ width: '95%' }} disabled={checkaddress != "Tôi muốn gửi về địa chỉ"} className="form-control" required={checkaddress == "Tôi muốn gửi về địa chỉ"} {...register('PHUONG_XA')} value={ward} onChange={(e) => handleChangeWard(e)}>
                                <option value={''} selected disabled>Phường/Xã</option>
                                {
                                  listWard && listWard.map((item, index) => (
                                    <option key={index} value={item.WardName}>{item.WardName}</option>
                                  ))
                                }
                              </select>

                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <br />
                    {checkaddress == "Tôi muốn gửi về địa chỉ" && (
                      <>
                        <div className="col-md-12" style={{ display: 'flex' }}>

                          <label className="col-form-label col-md-3"><span style={{ marginLeft: '25px' }}>Địa chỉ cụ thể</span> </label>

                          <input
                            style={{ width: '75%' }}
                            required={checkaddress == "Tôi muốn gửi về địa chỉ"}
                            className='input_yeucau col-md-10'
                            {...register('DIA_CHI_GUI_HANG_CHO_KHACH')} />


                        </div> <br />
                        <div className="col-md-12" style={{ display: 'flex' }}>

                          <label className="col-form-label col-md-3"><span style={{ marginLeft: '25px' }}>Người nhận </span> </label>

                          <input
                            style={{ width: '75%' }}
                            required={checkaddress == "Tôi muốn gửi về địa chỉ"}
                            className='input_yeucau col-md-10'
                            {...register('NGUOI_NHAN')} />


                        </div> <br />
                        <div className="col-md-12" style={{ display: 'flex' }}>

                          <label className="col-form-label col-md-3"><span style={{ marginLeft: '25px' }}>Số điện thoại </span> </label>

                          <input
                            style={{ width: '75%' }}
                            required={checkaddress == "Tôi muốn gửi về địa chỉ"}
                            className='input_yeucau col-md-10'
                            {...register('SO_DIEN_THOAI_NGUOI_NHAN')} />


                        </div>
                        <br />
                      </>

                    )}


                    <div className="col-md-12" style={{ display: 'flex', alignItems: 'center' }}>

                      <input
                        {...register("GUI_HANG_VE_DIA_CHI")}
                        id="other"
                        type="radio"
                        value="Tôi sẽ thông báo sau"
                      />
                      <div class=" ms-2 text-sm font-medium" for="other" >
                        Tôi sẽ thông báo sau {" "}
                      </div>


                    </div>  <br />


                    <div className="col-md-12">
                      <div className="title_important">
                        Bạn cần chúng tôi lưu ý điều gì không?
                      </div>
                      <div className="my-2">
                        <textarea
                          {...register("LUU_Y_TU_KHACH_HANG")}
                          style={{
                            height: '60px',
                            outline: "none",
                            width: "100%", borderRadius: '6px'
                          }}
                          class="border-b border-none p-2.5 text-sm "

                        />
                      </div>
                    </div>
                    <div className="col-md-12 prestep_button">
                      <button
                        className="hover_button_tracuu mb-3 rounded  border  bg-transparent px-4 py-2  font-semibold rounded-md shadow-lg "
                        style={{ marginRight: '5px' }}
                        onClick={() => setCurrentTab(3)}
                      >
                        Quay lại
                      </button>
                      <button type="submit"
                        className="hover_button_tracuu mb-3 rounded  border  bg-transparent px-4 py-2  font-semibold shadow-lg hover:border-transparent"

                      >
                        Đăng ký
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-2 csbh_image_banner ">
                    <img alt="Hình ảnh" style={{ height: '20rem' }} className="h-48 w-full object-cover md:h-full md:w-48" src={imageBanner1} />
                  </div>

                </div>

              </div>
            )}
          </form>
          {/* ------ */}
          {currentTab == "5" && (
            <div className="Tab 5">
              <h4 className="fw-bold text-center title_important ">TẠO PHIẾU YÊU CẦU BẢO HÀNH - SỬA CHỮA ONLINE</h4>
              <div className="content row mt-5" >

                <div className="col-sm-10">
                  <ul className="list-unstyled">
                    <li className="fw-bold mb-2">
                      <p className="title_important" style={{ color: "#0070C0", fontSize: '20px' }}>Chúc mừng, bạn đã tạo phiếu yêu cầu thành công!</p>
                    </li>
                    <li className="mb-2 mt-4">
                      <p>Mã số yêu cầu của bạn là <span className="yeucau_masochungtu">{resultTaoBaoHanhWeb?.SO_CHUNG_TU} </span>.</p>
                    </li>
                    <li className="mb-2 mt-3">
                      <p>Bạn có thể theo dõi quá trình xử lý yêu cầu của mình <a style={{ textDecoration: 'underline' }} className="daily_question" target="_blank" href="/tra-cuu-bao-hanh">tại đây</a></p>
                    </li>

                  </ul>
                  <ul className="list-unstyled" style={{ marginTop: '52px' }}>
                    <li className="fw-bold mb-2">
                      <p className="title_important" style={{ fontSize: '16px' }}>Vui lòng gửi sản phẩm về Trung tâm bảo hành của chúng tôi theo địa chỉ sau:</p>
                    </li>
                    <li className="mb-2">
                      <p>{resultTaoBaoHanhWeb?.TRUNG_TAM_BAO_HANH}</p>
                    </li>
                    <li className="mb-2">
                      <p>SĐT: {resultTaoBaoHanhWeb?.SO_DIEN_THOAI}</p>
                    </li>

                  </ul>
                  <ul className="list-unstyled" style={{ marginTop: '30px' }}>
                    <li className="fw-bold mb-2">
                      <p className="title_important" style={{ fontSize: '16px' }}>Xin lưu ý:</p>
                    </li>
                    <li className="mb-2">
                      <p>📌 Sản phẩm cần được <span className="title_important"> gửi cùng toàn bộ phụ kiện </span>đi kèm (trừ khi có hướng dẫn khác của nhân viên GIGACARE). </p>
                      <p>Các sản phẩm có bình chứa nước, vui lòng <span className="title_important">đổ hết nước </span> để sản phẩm khô ráo trước khi đóng gói.</p>
                    </li>
                    <li className="mb-2">
                      <p>📌 Bưu kiện cần ghi rõ Số điện thoại liên hệ và mã số yêu cầu <span className="yeucau_masochungtu">{resultTaoBaoHanhWeb?.SO_CHUNG_TU}</span>.</p>
                    </li>
                    <li className="mb-2">
                      <p>📌 Bạn vui lòng thanh toán phí vận chuyển khi gửi sản phẩm đi, chúng tôi sẽ hỗ trợ phí vận chuyển khi hoàn trả sản phẩm sau bảo hành.</p>
                    </li>
                    <li>
                      (Thời gian xử lý yêu cầu có thể bị kéo dài, nếu sản phẩm gửi đến thiếu phụ kiện, thiếu thông tin hoặc chưa được thanh toán phí)
                    </li>
                  </ul>

                  <ul className="list-unstyled" style={{ marginTop: '30px' }}>
                    <li className="fw-bold mb-2">
                      <p>Vui lòng đọc kỹ Chính sách và quy trình bảo hành của chúng tôi <a className="daily_question" style={{ textDecoration: 'underline' }} target="_blank" href="/chinh-sach-bao-hanh">tại đây.</a> </p>
                    </li>
                    <li className="mb-2">
                      <p>Mọi thắc mắc vui lòng liên hệ nhân viên bán hàng hoặc hotline <span className="title_important">1900.633.870</span>   (8-21h hằng ngày).</p>
                    </li> <br />
                    <li className="mb-2 title_important">
                      <p>GIGACARE xin cảm ơn quý khách!</p>
                    </li>

                  </ul>
                </div>
                <div className="col-sm-2 csbh_image_banner ">
                  <img alt="Hình ảnh" style={{ height: '20rem' }} className="h-48 w-full object-cover md:h-full md:w-48" src={imageBanner1} />
                </div>

              </div>

            </div>
          )}

        </div>
      </div>
    </>
  );
};
export default YeuCau;
