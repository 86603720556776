import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll'

//image xanh
import ImgKichHoat from '../../assets/image/action/h-kich-hoat-1.png'
import ImgHuongDan from '../../assets/image/action/h-huong-dan-1.png'
import ImgTraCuu from '../../assets/image/action/h-tra-cuu-1.png'
import ImgTram from '../../assets/image/action/h-tram-1.png'

import ImgYeuCau from '../../assets/image/action/h-yeu-cau-1.png'
import ImgTaoPhieu from '../../assets/image/action/contact-form.png'

import ImgKhuyenCao from '../../assets/image/action/contract.png'

import ImgTaoPhieuWhite from "../../assets/image/action/contact-formwhite.png";
import ImgKhuyenCaoWhite from "../../assets/image/action/contractwhite.png";

import ImgKichHoatWhite from "../../assets/image/action/h-kich-hoat-at.png";
import ImgHuongDanWhite from "../../assets/image/action/h-huong-dan-at.png";
import ImgTraCuuWhite from "../../assets/image/action/h-tra-cuu-at.png";
import ImgYeuCauWhite from "../../assets/image/action/h-yeu-cau-at.png";
//image trắng


const CheckBaoHanh = () => {
 
  return (
    <>
    <div className='giga-electric'>
      <div className='bg-white info'>
      <div className="header position-relative">
          <div className="text-banner">
          <ScrollAnimation
              animateIn="bounceInLeft"
              animateOut="bounceInRight"
              duration={1.5}
              delay={0}
              animateOnce={true}
            >
          <h1 className=" text-center fw-bold title_home">
              CHÀO MỪNG BẠN ĐẾN VỚI TRANG DỊCH VỤ HỖ TRỢ VÀ BẢO HÀNH CỦA GIGACARE
              </h1>
              </ScrollAnimation>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceInLeft"
              duration={1.5}
              delay={0}
              animateOnce={true}
            >
             <p className="text-center title_home_content" style={{color:'#fff',fontWeight:'bold'}}> 
            TRUNG TÂM BẢO HÀNH ỦY QUYỀN CHÍNH HÃNG CỦA CÁC THƯƠNG HIỆU ECOVACS, TINECO, KINGSMITH, NEW WIDETECH, LUMIAS...
              </p>
            </ScrollAnimation>
          </div>
        </div>
        {/* <div className='header_content_title p-2 justify-content-around'>
          <div className='header-top'>
            <i className='fa fa-check mr-5 float-left' ></i>
            <p className='d-flex'> Bảo hành tiêu chuẩn nhà sản xuất</p>
          </div>
          <div className='header-top'>
            <i className='fa fa-check mr-5 float-left' ></i>
            <p className='d-flex'>Tư vấn online nhanh chóng</p>
          </div>
          <div className='header-top'>
            <i className='fa fa-check mr-5 float-left' ></i>
            <p className='d-flex'> Dịch vụ uy tín - chuyên nghiệp</p>
          </div>
        </div> */}
        <div className='container'>
          <div className='list-items-button mb-5'>
          <h2 className="text-center w-100 fw-bold text-capitalize mt-5">
              DỊCH VỤ VÀ HỖ TRỢ
            </h2>
            <Link to={'/kich-hoat-bao-hanh'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgKichHoat} />
                <img src={ImgKichHoatWhite} className="img-2" />
                <p className='text-black fw-bold mt-2'>Kích hoạt bảo hành</p>
              </div>
            </Link>

            <Link to={'/thoi-han-bao-hanh'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgYeuCau} />
                <img src={ImgYeuCauWhite} className="img-2" />
                <p className='text-black fw-bold mt-2'>Tra cứu thời hạn bảo hành</p>
              </div>
            </Link>

            <Link to={'/gui-yeu-cau'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
             
              <img src={ImgTaoPhieu} />
              <img src={ImgTaoPhieuWhite} className="img-2" />
                <p className='text-black fw-bold mt-2'>Tạo phiếu yêu cầu bảo hành</p>
              </div>
            </Link>

            <Link to={'/chinh-sach-bao-hanh'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgHuongDan} />
                <img src={ImgHuongDanWhite} className="img-2" />
                <p className='text-black fw-bold mt-2'>Chính sách bảo hành</p>
              </div>
            </Link>

            <Link to={'/khuyen-cao-su-dung'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
              <img src={ImgKhuyenCao}  />
                <img src={ImgKhuyenCaoWhite} className="img-2" />
                <p className='text-black fw-bold mt-2'>Khuyến cáo khi sử dụng Robot hút bụi</p>
              </div>
            </Link>

            <Link to={'/tra-cuu-bao-hanh'} className='item-action d-table'>
              <div className='box-content d-table-cell align-middle'>
                <img src={ImgTraCuu} />
                <img src={ImgTraCuuWhite} className="img-2" />
                <p className='text-black fw-bold mt-2'>Tra cứu tiến độ bảo hành</p>
              </div>
            </Link>
            
          </div>
       
         
        </div>
       
      </div>
    </div>
    <div className="py-4 homepage-notice mb-5">
            <h2 className="text-center w-100 fw-bold text-capitalize mt-4 mb-5">
              LIÊN HỆ BỘ PHẬN HỖ TRỢ
            </h2>
            <h4
              className="text-center mx-8 "
              style={{ textTransform: "upperCase" }}
            >
              Vui lòng liên hệ với chúng tôi <br/> nếu thông tin trên không thể 
              giải quyết được vấn đề của bạn
            </h4>
            <div>
              <button className="readmore_button mt-4 mb-4">
                <input
                  type="tel"
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    outline: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  readOnly
                  value={"HOTLINE : 1900.633.870"}
                />
              </button>
            </div>
            <div className='content-text text-center mb-4 mt-3'>
            <p><strong>Đối với Quý khách hàng, chúng tôi luôn mang đến những sản phẩm với chất lượng tốt nhất.</strong></p>
            <p className='mt-2'><strong>Cùng với đó, sự quan tâm hàng đầu của chúng tôi là đặc biệt chú trọng đến dịch vụ chăm sóc, bảo hành trong suốt vòng đời sản phẩm.</strong></p>
          </div>
          </div>
    </>
  )
}

export default CheckBaoHanh